<template>
	<div>
		<!--div class="text-center legacy-app">
			<small>
				You are Signing to Ohmylead's new release. If you need to go back to our legacy app. <a href="https://app.ohmylead.com" >Click here</a>
			</small>
		</div-->
		
		<div class="event_processing" v-if="LoadingFb">
			<!--i class="fa fa-spinner fa-spin fa-3x fa-fw"></i-->
			<img src="/img/ajax-loader.gif" />
		</div>
		
		<meta name="viewport" content="width=device-width,initial-scale=1.0">
		<meta name="viewport" content="width=500, initial-scale=1">
		<meta name="viewport" content="initial-scale=1, maximum-scale=1">
		
		<div class="col-md-12 login" v-if="LoadingFb == false">
			<section class="hbox stretch">
				<div class="logins_pages">
					<div class="row">
						
						<div class="col-md-3"></div>
						<div class="col-md-6"> <!--  col-md-offset-3 -->
							<div class="login_left-side login_bouless">
								<div class="login_left-side-body">
									<div class="logo text-center" style="margin-left: auto; margin-right: auto;">
										<router-link :to="{name: 'home'}"><img :src="$store.state.DefaultLogo" style="width: 230px;" alt="" /></router-link>
									</div>
									<div class="login_text-heading text-center" style="color: #2b334a; margin-bottom: 30px;">
										<h4 v-if="!this.isWhiteLabel">{{ $t('login_title') }}</h4>
									</div>
									
									<div class="login_form" style="padding: 0 1rem;">
										<div class="row">
											<div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
												<div class="row">
													<div class="col-md-12">
														<form autocomplete="on" @submit.prevent="login" method="post" id="form-login-gold">
															<div class="form-group"><!-- has-error -->
																<label class="text-left" for="email-signin">{{ $t('Email') }}</label>
																<input type="text" name="email" id="email-signin" class="form-control" :placeholder="$t('YourWorkEmail')" v-model="email" required autofocus />
															</div>
															<div class="form-group"><!-- has-error -->
																<label class="text-left" for="password-signin">{{ $t('Password') }}</label>
																<input type="password" name="password" id="password-signin" v-model="password" class="form-control" :placeholder="$t('TypeYourPassword')" required />
															</div>
															<span class="help-block" v-if="has_error">
																<strong>{{ $t('login_validate') }}</strong>
															</span>
															
															<div class="row align-items-center" v-if="success">
																<div class="col-12">
																	<div class="alert alert-success mb-0" id="signin-success-alert" style="font-size: 12px;">{{ $t('WelcomeBack')}}</div>
																</div>
															</div>
															<!--input type="submit" class="btn-login" value="Login to your account"-->
															<button type="submit" class="btn-login mb-2">{{ $t('login_account') }} <i class="fa fa-spinner fa-spin" v-show="processing"></i></button>
														</form>
														<!--div class="row text-center">
															<div class="col-5"><hr style="border-color: #b7b7b7;"></div>
															<div class="col-2 my-auto">OR</div>
															<div class="col-5"><hr style="border-color: #b7b7b7;"></div>
														</div>
														<div class="mt-2 mb-5">
															<v-facebook-login 
																app-id="155127881962747" 
																:loginOptions="{ scope: 'ads_management,manage_pages,public_profile,leads_retrieval', return_scopes : true }" 
																@sdk-init="sdkLoaded" 
																@login="onLogin" 
																@logout="onLogout">
															</v-facebook-login>
														</div-->
														
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-6 col-sm-6">
														<div class="meta" style="border: 0">
															<router-link :to="{name: 'register'}" class="no-account pull-left">{{ $t('login_accountcheck') }}</router-link>
														</div>
													</div>
													<div class="col-md-6 col-6 col-sm-6">
														<div class="meta" style="border: 0">
															<router-link :to="{name: 'forgot'}" class="forgot-password pull-right">{{ $t('ForgotPassword') }}</router-link>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3"></div>
					</div>
				</div>
			</section>
		</div>
		
	</div>
</template>
<script>
	// import { VFBLogin as VFacebookLogin } from 'vue-facebook-login-component'
	export default {
		data() {
			return {
				email		: null,
				password	: null,
				token		: '',
				has_error	: false,
				processing	: false,
				LoadingFb	: false,
				success		: false,
				
				country_code: '',
				timezone	: '',
				isWhiteLabel  : false
			}
		},
		components	: {
			// VFacebookLogin,
		},
		mounted(){
			if(window.location.href.indexOf("https://console.ohmylead.com") !== -1){
				this.isWhiteLabel = false
			}else{
				this.isWhiteLabel = true
			}
		},
		created() {
			//
			let that = this
			if ( typeof( that.$route.params.token ) != "undefined" ) {
				let token = that.$route.params.token
				if ( token.includes("fbauth-") ) {
					that.email = token.replace('fbauth-', '')
					
					that.processing = true
					that.LoadingFb 	= true
					that.LoginFb()
					
				} else {
					that.token = token
				}
			}
			that.FetchCountry()
		},
		methods: {
			
			FetchCountry() {
				let that = this
				fetch('https://extreme-ip-lookup.com/json/').then( res => res.json() ).then(resp => {
					
					that.country_code	= resp.countryCode
					that.timezone		= resp.continent + '/' + resp.city
					
				}).catch(() => {
					
				})
				
			},
			sdkLoaded() {},
			
			onLogin(AuthData) {
				// console.log(AuthData)
				let that = this
				if (AuthData.status == "connected") {
					
					let RequestData = AuthData.authResponse
					
					let params = { limit: 200, access_token: AuthData.authResponse.accessToken };
					window.FB.api('/me', 'GET', params, { fields: 'id,name,email' },
					(response) => {
						RequestData.name 	= response.name
						RequestData.email 	= response.email
						// RequestData.client_id = ''
						RequestData.grantedScopes = 'pages_show_list,ads_management,business_management,leads_retrieval,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,public_profile,email'
						
						that.handleSessionResponse(RequestData)
					})
					let url   = '/user'
					let method= 'GET'
					let data  = {}
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						
						let feedback   = response.data
						let user = feedback.user
						
						if(user.language == 'au' || user.language==null || typeof user.language !== 'undefined' ){
							if(navigator.language.indexOf("fr") !== -1){
								localStorage.setItem('i18n_lang' ,'fr' )
							}else{
								localStorage.setItem('i18n_lang' ,'en' )
							}
						}else{
							localStorage.setItem('i18n_lang' ,user.language )
						}
						
					}).catch(( {response} ) => {
						console.log(response)
						
					})
					
				}
			},
			handleSessionResponse(response) {
				
				let that = this
				let DataFb = response
				
				that.has_error = false
				that.processing = true
				
				DataFb.country_code 	= that.country_code
				DataFb.timezone 		= that.timezone
				
				that.$store.dispatch('LoginFbBtn', DataFb).then( ({data}) => {
					/* console.log( data ) */
					that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
					if ( typeof( data.user.avatar ) === 'undefined' || data.user.avatar == null || data.user.avatar == '' ) {
						data.user.avatar = ''
					}
					that.$auth.user  = (() => {
						return  data.user
					})
					
					window.localStorage.setItem('oml-token', data.access_token);
					window.localStorage.setItem('oml-user', JSON.stringify(data.user) );
					
					
					// window.localStorage.setItem('action', 'bl')
					that.success = true
					that.$store.dispatch('clientSession').then( () => {
						if( data.lead ) {
							// that.$router.push({name: 'myleads'})
							window.location = '/myleads'
						} else {
							// that.$router.push({name: 'newSource'})
							window.location = '/newsource'
						}
						that.processing = false
					})
					
					// const redirectTo = redirect ? redirect.from.name : this.$auth.user().role === 'sysadmin' ? 'admin.dashboard' : 'myleads'
					// this.$router.push({name: redirectTo})
				}).catch( () => {
					that.processing = false
					that.has_error = true
				})
				
			},
			onLogout() {},
			
			LoginFb() {
				
				var that = this
				that.has_error 	= false
				that.processing = true
				that.LoadingFb 	= true
				let email	 	= that.email
				
				that.$store.dispatch('LoginFb', { email }).then( ({data}) => {
					
					that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
					if ( typeof( data.user.avatar ) === 'undefined' || data.user.avatar == null || data.user.avatar == '' ) {
						data.user.avatar = ''
					}
					that.$auth.user  = (() => {
						return  data.user
					})
					window.localStorage.setItem('fbauth', 'facebook')
					window.localStorage.setItem('oml-token', data.access_token);
					window.localStorage.setItem('calendly_url', data.calendly_url )
					window.localStorage.setItem('oml-user', JSON.stringify(data.user) );
					that.success = true
					
					let authResponse = data.authResponse
						authResponse = {
							accessToken		: authResponse.facebook_access_token,
							userID			: authResponse.facebook_user_id,
							expiresIn		: authResponse.facebook_expires_in,
							signedRequest	: "",
							graphDomain		: "facebook",
							grantedScopes	: authResponse.facebook_granted_scopes,
							data_access_expiration_time:""
						}
					window.localStorage.setItem('fb-authResponse', JSON.stringify( authResponse ) )
					// window.localStorage.setItem('action', 'bl')
					that.$store.dispatch('clientSession').then( () => {
						window.location = '/newSource/facebook'
						that.LoadingFb = false
						that.processing = false
					})
					
				}).catch( () => {
					this.LoadingFb = false
					this.processing = false
					this.has_error = true
				})
			},
			
			login: function () {
				// var redirect = this.$auth.redirect()
				var that = this
				
				that.has_error = false
				that.processing = true
				
				// localStorage.removeItem('user-token')
				// axios.defaults.headers.common['Authorization'] = token
				
				let email 	 = that.email 
				let password = that.password
				let token	 = that.token
				that.$store.dispatch('login', { email, password, token,lang : navigator.language }).then( /* () => that.$router.push('/') */ ({data}) => {
					/* console.log( data ) */
					that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
					if ( typeof( data.user.avatar ) === 'undefined' || data.user.avatar == null || data.user.avatar == '' ) {
						data.user.avatar = ''
					}
					that.$auth.user  = (() => {
						return  data.user
					})
					
					window.localStorage.setItem('oml-token', data.access_token);
					window.localStorage.setItem('oml-user', JSON.stringify(data.user) );
					window.localStorage.setItem('calendly_url', data.calendly_url)
					
					// window.localStorage.setItem('action', 'bl')
					that.success = true
					that.$store.dispatch('clientSession').then( () => {
						if( data.lead ) {
							// that.$router.push({name: 'myleads'})
							window.location = '/myleads'
						} else {
							// that.$router.push({name: 'newSource'})
							window.location = '/newsource'
						}
						that.processing = false
					})
					
					// const redirectTo = redirect ? redirect.from.name : this.$auth.user().role === 'sysadmin' ? 'admin.dashboard' : 'myleads'
					// this.$router.push({name: redirectTo})
				}).catch( /* err => console.log(err) */ ( response  ) => {
					console.log(response)
					this.processing = false
					this.has_error = true
				})
			},
		}
	}
</script>
<style lang="scss">
	.legacy-app {
		background-color: #6afdef;
		padding: 10px 24px;
	}
	
	.logins_pages, .register-fb {
		.v-facebook-login {
			width: 100%;
			height: 48px;
		}
	}
</style>
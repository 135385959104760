<template>
	<div class="container-fluid register p-0">
		
		<meta name="viewport" content="width=device-width,initial-scale=1.0">
		<meta name="viewport" content="width=500, initial-scale=1">
		<meta name="viewport" content="initial-scale=1, maximum-scale=1">
		
		<!-- Form -->
		<div class="row">
			
			<div class="col-lg-5 col-xl-4 d-none d-lg-flex gradient-half-primary-v1" style="position: fixed; overflow-y: scroll;height: 100vh;z-index: 9;">
				<div class="w-100 p-5" style="display:flex; flex-direction: column; justify-content: space-evenly; height: 100%;">
					<div style="margin-bottom: 2rem; margin-top: 0rem;"> <!-- js-slick-carousel u-slick  -->
						<div class="slick-list draggable">
							<!-- Testimonial -->
							<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px;" v-for="(slide, key) in sliderReview" v-show="slide.selected" v-bind:key="`slideR-${key}`" @click="sliderSelect(slide)">
								<div class="text-left mb-0">
									<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;" v-html="slide.description"></blockquote>
								</div>
								<div class="d-flex justify-content-start align-items-center mt-2">
									<div class="d-flex align-items-center justify-content-start">
										<img :src="slide.avatar" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
										<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">{{ slide.name }}<br><span class="text-muted" style="font-size: .7rem;">{{ slide.job }}</span></h4>
									</div>
								</div>
							</div>
							<!-- End Testimonial -->
						</div>
						<ul class="js-pagination text-left u-slick__pagination mt-4 mb-0 justify-content-start" role="tablist">
							<li v-for="(slide, key) in sliderReview" :class="[ slide.selected ? 'slick-active-right slick-active slick-current' : '']" @click="sliderSelect(slide)" role="presentation" v-bind:key="`slide-${key}`">
								<span></span>
							</li>
						</ul>
					</div>
					<div class="py-3 px-2 px-sm-9 mt-3 mb-2">
						<!-- Info -->
						<ul class="list-unstyled">
							<li class="media pb-3">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark.svg" class="img-checkmark" />
								</div>
								<div class="media-body">
									<h4 class="h6 text-white mb-1">{{$t('AbsolutelyFreeToTry')}}</h4>
									<p class="text-white-70" style="font-size: .8rem; margin-bottom: 0;">{{$t('Message1')}}</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark.svg" class="img-checkmark" />
								</div>
								<div class="media-body">
									<h4 class="h6 text-white mb-1">{{$t('CancelOrDowngradeAnytime')}}</h4>
									<p class="text-white-70" style="font-size: .8rem;">{{$t('Message2')}}</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark.svg" class="img-checkmark" />
								</div>
								<div class="media-body">
									<h4 class="h6 text-white mb-1">{{$t('SatisfiedOrMoneyBack')}}</h4>
									<p class="text-white-70" style="font-size: .8rem; margin-bottom: 0;">{{$t('Message1')}}</p>
								</div>
							</li>
						</ul>
						<!-- End Info -->
					</div>
					<!-- End Quote -->
				</div>
			</div>
			<div class="col-12">
				<div class="row no-gutters">
					<div class="col-12 col-xl-5 col-lg-6 offset-lg-6 offset-xl-6 space-3 space-lg-0 ohmylead">
						<form class="js-validate mt-5 pt-2" @submit.prevent="register">
							<div class="mb-4 mt-3">
								<a href="https://www.ohmylead.com"><img :src="$store.state.DefaultLogo" class="logo-icon logo" style="width: 30%; margin: 0;"></a>
							</div>
							<!-- Title -->
							<div class="mt-3 mb-4"  v-if="PlanQuery.plan_name!='whitelabel'">
								<h3 class="dark-blue-text" v-if="!isWhiteLabel" style="font-size: 1.2rem; font-weight: 500;">{{$t('StartYourFreeTrial')}}</h3>
								<p style="font-size: 14px;" v-if="!isWhiteLabel" v-html="$t('YourTrialUntil',{ until: StartedDay })"></p>
							</div>
							
							<div class="alert text-info p-0" style="font-size: 14px;">
								<i class="fa fa-info-circle" style="font-size: 14px;"></i> {{ $t('YouHaveChosen')}} <b>{{ PlanQuery.plan_name }} {{ $t('plan')}} <span v-if="PlanQuery.plan_name!='whitelabel'">(Unlimited {{ $t('leads')}} )</span></b>
							</div>
							
							<!-- Form Group -->
							<div class="row">
								
								<div class="js-form-message form-group col-md-6 col-sm-12 col-lg-6" v-for="(item, id) in PlansList" :key="`${id}`">
									<div v-if="PlanQuery.plan_name!='whitelabel'">
										
									<div :class="['plan-range', PlanQuery.plan_type == item.plan_type ? 'active' : '']" @click="ChoosePlan(item)">
										<div class="form-check pr-2">
											<input class="form-check-input" type="radio" v-model="PlanQuery.plan_type" :value="item.plan_type">
										</div>
										
										<div>
											<div class="mb-2" style="font-size: 14px;"><b>{{ item.title }}</b></div>
											<div class="text-primary" style="font-size: 18px;"  v-if="PlanQuery.plan_name!='whitelabel'">{{$t('DueToday')}} <b>0 $</b></div>
											<div class="text-primary" style="font-size: 18px;"  v-if="PlanQuery.plan_name=='whitelabel'">{{$t('DueToday')}} <b>{{ item.price }} $</b></div>
											<div v-if="PlanQuery.plan_length != 250 &&  PlanQuery.plan_name!='whitelabel'" class="text-primary mt-1" style="font-size: 13px;">{{$t('Then')}} {{ '$' + item.price }}/{{ item.plan_type }} {{$t('After 14 Days')}}</div>
											<!--div class="text-secondary mt-2"><small>{{ item.description }}</small></div-->
										</div>
									</div>

									</div>

									<div v-if="PlanQuery.plan_type == item.plan_type && PlanQuery.plan_name=='whitelabel'">
										
										<div :class="['plan-range', PlanQuery.plan_type == item.plan_type ? 'active' : '']" @click="ChoosePlan(item)">
											<div class="form-check pr-2">
												<input class="form-check-input" type="radio" v-model="PlanQuery.plan_type" :value="item.plan_type">
											</div>
											
											<div>
												<div class="mb-2" style="font-size: 14px;"><b>{{ item.title }}</b></div>
												<div class="text-primary" style="font-size: 18px;"  v-if="PlanQuery.plan_name!='whitelabel'">{{$t('DueToday')}} <b>0 $</b></div>
												<div class="text-primary" style="font-size: 18px;"  v-if="PlanQuery.plan_name=='whitelabel'">{{$t('DueToday')}} <b>{{ item.price }} $</b></div>
												<div v-if="PlanQuery.plan_length != 250 &&  PlanQuery.plan_name!='whitelabel'" class="text-primary mt-1" style="font-size: 13px;">{{$t('Then')}} {{ '$' + item.price }}/{{ item.plan_type }} {{$t('After 14 Days')}}</div>
												<!--div class="text-secondary mt-2"><small>{{ item.description }}</small></div-->
											</div>
										</div>
	
										</div>
									
								</div>
								
							</div>
							
							<!-- Form Group -->
							<div class="row">
								<div class="js-form-message form-group col-6">
									<input type="text" class="form-control" @blur="validatorField('name')" :placeholder="$t('FirstLastName')" required v-model="registerArray.name" />
									<small class="form-text text-danger" v-if="validatorError.name != ''">{{ validatorError.name }}</small>
								</div>
								
								<div class="js-form-message form-group col-6">
									<vue-tel-input v-model="registerArray.phone" name="phone" id="phone" @onInput="onInput" :validate="'min:3'" :placeholder="$t('Phone')" required></vue-tel-input>
									<small class="form-text text-danger" v-if="validatorError.phone != ''">{{ validatorError.phone }}</small>
								</div>
							</div>
							
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<input type="email" class="form-control" id="yourEmail" @blur="validatorField('email')" 
									:placeholder="$t('Email')" v-model="registerArray.email" 
									required /><!-- pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" -->
								<small class="form-text text-danger" v-if="validatorError.email != ''">{{ validatorError.email }}</small>
							</div>
							<!-- End Form Group -->
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<div class="input-group">
									<input :type="[showPwd ? 'text' : 'password']" class="form-control" id="password" :placeholder="$t('TypeYourPassword')" @blur="validatorField('password')" required v-model="registerArray.password" />
									<div class="input-group-append">
										<a href="javascript:void(0)" class="input-group-text" id="basic-addon2" style="background-color: #fff;" @click="showPwd = !showPwd">
											<span v-if="showPwd">{{$t('Hide')}}</span>
											<span v-else>{{$t('Show')}}</span>
											<!--i class="fa fa-eye-slash"></i-->
										</a>
									</div>
								</div>
								<small class="form-text text-danger" v-if="validatorError.password != ''">{{ validatorError.password }}</small>
							</div>
							<!-- End Form Group -->
							
							<div class="alert alert-danger" role="alert" v-if="feedbackMessage != ''">
								<small class="form-text"> <!--i class="fa fa-exclamation-triangle"></i--> <span v-html="feedbackMessage"></span></small>
							</div>
							<!-- Button -->
							<div class="row align-items-center mb-5">
								<div class="col-12 col-lg-7">
									<span class="small text-muted">{{ $t('AlreadyHaveAccount')}} </span>
									<router-link :to="{name: 'login'}" class="small">{{$t('Login')}}</router-link>
								</div>
								<div class="col-12 col-lg-5 text-right">
									<button type="submit" class="btn btn-primary btn-block btn-getstarted">{{$t('GetStarted')}} <i class="fa fa-spinner fa-spin" v-show="processing"></i></button><!-- transition-3d-hover -->
								</div>
							</div>
							<!-- End Button -->
						</form>
					</div>
				</div>
				<div class="row d-lg-none">
					<div class="col-12">
						<div class="js-slick-carousel u-slick mobile-testimonials" style="margin-bottom: 2rem; margin-top: 0rem; display:none;">
							<!-- Testimonial -->
							<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px; background-color: #377cff;">
								<div class="text-left mb-0">
									<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;">{{$t('Review1')}}
									</blockquote>
								</div>
								<div class="d-flex justify-content-start align-items-center mt-2">
									<div class="d-flex align-items-center justify-content-start">
										<img src="https://old.ohmylead.com/assets/v2/img/david-somerfleck.png" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
										<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">David Somerfleck<br><span class="text-muted" style="font-size: .7rem;">Digital Expert at DMS - Florida</span></h4>
									</div>
								</div>
							</div>
							<!-- End Testimonial -->
							<!-- Testimonial -->
							<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px;">
								<div class="text-left mb-0">
									<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;">{{$t('Review2')}}
									</blockquote>
								</div>
								<div class="d-flex justify-content-start align-items-center mt-2">
									<div class="d-flex align-items-center justify-content-start">
										<img src="https://old.ohmylead.com/assets/v2/img/martin-belhoste.png" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
										<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">
											Martin Belhoste<br><span class="text-muted" style="font-size: .7rem;">Sales manager at Moët Hennessy - France</span>
										</h4>
									</div>
								</div>
							</div>
							<!-- End Testimonial -->
							<!-- Testimonial -->
							<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px;">
								<div class="text-left mb-0">
									<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;">{{$t('Review3')}}</blockquote>
								</div>
								<div class="d-flex justify-content-start align-items-center mt-2">
									<div class="d-flex align-items-center justify-content-start">
										<img src="https://old.ohmylead.com/assets/v2/img/haim-pekel.png" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
										<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">
											Haim pekel<br><span class="text-muted" style="font-size: .7rem;">CEO at Press On it - London</span>
										</h4>
									</div>
								</div>
							</div>
							<!-- End Testimonial -->
						</div>
						
						<div class="mobile-testimonials" style="margin-bottom: 2rem; margin-top: 0rem;"> <!-- js-slick-carousel u-slick  -->
							<div class="slick-list draggable">
								<!-- Testimonial -->
								<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px;" v-for="(slide, key) in sliderReview" v-show="slide.selected" v-bind:key="`sld-${key}`">
									<div class="text-left mb-0">
										<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;" v-html="slide.description"></blockquote>
									</div>
									<div class="d-flex justify-content-start align-items-center mt-2">
										<div class="d-flex align-items-center justify-content-start">
											<img :src="slide.avatar" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
											<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">{{ slide.name }}<br><span class="text-muted" style="font-size: .7rem;">{{ slide.job }}</span></h4>
										</div>
									</div>
								</div>
								<!-- End Testimonial -->
							</div>
							<ul class="js-pagination text-left u-slick__pagination mt-4 mb-0 justify-content-start" style="" role="tablist">
								<li v-for="(slide, key) in sliderReview" :class="[ slide.selected ? 'slick-active-right slick-active slick-current' : '']" @click="sliderSelect(slide)" role="presentation" v-bind:key="`sl-${key}`">
									<span></span>
								</li>
							</ul>
						</div>
						
						<ul class="list-unstyled">
							<li class="media pb-3">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline" style="background: #f0f8ff;">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark-blue.svg">
								</div>
								<div class="media-body">
									<h4 class="h6 mb-1">{{ $t('AbsolutelyFreeToTry')}}</h4>
									<p class="" style="font-size: .8rem; margin-bottom: 0;">No catch. See it for yourself for 14 days absolutely free.</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3 d-none"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline" style="background: #f0f8ff;">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark-blue.svg">
								</div>
								<div class="media-body">
									<h4 class="h6 mb-1">Cancel or Downgrade anytime</h4>
									<p class="" style="font-size: .8rem;">You can upgrade, downgrade or cancel your subscription anytime. No questions asked.</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline" style="background: #f0f8ff;">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark-blue.svg">
								</div>
								<div class="media-body">
									<h4 class="h6 mb-1">Satisfied or Money Back</h4>
									<p class="" style="font-size: .8rem; margin-bottom: 0;">Get your money back in less than 24h, after using our software for 30 days.</p>
								</div>
							</li>
						</ul>
						<!-- End Clients Section -->
						<!--a href="/register" class="btn btn-block" style="background-color: #f0f8ff; color: #377dff; margin-bottom: 2rem; font-size: .9rem;">Go up <img style="width: 20px;" src="https://old.ohmylead.com/assets/v2/img/arrow-up.svg"></a-->
					</div>
				</div>
			</div>
		</div>
		<!-- End Form -->
		
	</div>
</template>
<script>
	import { VueTelInput } from 'vue-tel-input'
	
	export default {
		data() {
			return {
				showPwd		   : false,
				registerArray  : {
					name   	   : '',
					email	   : '',
					password   : '',
					token 	   : '',
					phone	   : '',
					telCode    : '',
					country_code: '',
					timezone   	: '',
					plan_id   	: '',
					interface 	: 'signup_cc',
					utm_source:'',
					utm_campaign:'',
					utm_medium : '',
					utm_term:''
				},
				
				// password_confirmation: '',
				validatorError	: { name:'', phone:'', email:'', password:'' },
				feedbackMessage : '',
				processing		: false,
				
				sliderReview	: [
					{
						id	:1,
						name:'David Somerfleck',
						selected: true,
						avatar: 'https://old.ohmylead.com/assets/v2/img/david-somerfleck.png',
						job	: 'Digital Expert at DMS - Florida',
						description:this.$t('Review1')
					},
					{
						id	:2,
						name:'Martin Belhoste',
						selected: false,
						avatar: 'https://old.ohmylead.com/assets/v2/img/martin-belhoste.png',
						job	: 'Sales manager at Moët Hennessy - France',
						description:this.$t('Review2')
					},
					{
						id	:3,
						name:'Haim pekel',
						selected: false,
						avatar: 'https://old.ohmylead.com/assets/v2/img/haim-pekel.png',
						job	: 'CEO at Press On it - London',
						description:this.$t('Review3')
					},
				],
				
				PlanQuery		: { plan_name: "Pro", plan_length: '5000', plan_type: 'year' },
				
				PlansList		: [
					{ id: '', name: '', plan_type: 'year',  total_leads: '', price: 0, title: 'Annually (Free 2 months)', description: 'Billed annually 999 Dollars' },
					{ id: '', name: '', plan_type: 'month', total_leads: '', price: 0, title: 'Monthly', description: 'Billed monthly' },
				],
				
			}
		},
		mounted() {
			//
				
			let that = this
			if( typeof( that.$route.params.token ) != "undefined" ) {
				that.registerArray.token = that.$route.params.token
			}
			
			let query = that.$route.query
			if( Object.keys(query).length ) {
				that.PlanQuery.plan_name	= query.pack
				that.PlanQuery.plan_length	= query.leads
				that.PlanQuery.plan_type	= query.plan
			}
			
			that.LoadPlan()
			that.getCountry()
		},
		methods: {
			
			sliderSelect(slide) {
				
				this.sliderReview.forEach( function(item) {
					item.selected = false
				} );
				slide.selected = !slide.selected
				
			},
			
			LoadPlan() {
                
				let that  = this
				let url   = '/plan/getSelectedPlanNewUnlimited'
				let method= 'POST'
				let data  = that.PlanQuery
				
				that.$http({ url: url, method: method, data: data }).then((response) => {
					
					let plans = response.data.plans
					
					plans.forEach( function(plan) {
						if( plan.plan_type == "year" ) {
							that.PlansList[0].id 	= plan.product_id
							that.PlansList[0].name 	= plan.name
							that.PlansList[0].price = plan.price
							that.PlansList[0].description = 'Billed annually ' + plan.price + ' Dollars'
							that.PlansList[0].total_leads = plan.total_leads
						} else {
							that.PlansList[1].id 		= plan.product_id
							that.PlansList[1].name 		= plan.name
							that.PlansList[1].price 	= plan.price
							that.PlansList[1].total_leads = plan.total_leads
						}
						if( plan.plan_type == that.PlanQuery.plan_type ) {
							that.registerArray.plan_id = plan.product_id
						}
					} )
				}).catch(( response ) => {
					console.log(response)
				})
				
            },
			
			getCountry() {
				// http://ip-api.com/json
				// https://extreme-ip-lookup.com/json/
				let that = this
				fetch('https://extreme-ip-lookup.com/json/').then( res => res.json()).then(response => {
					// console.log(response);
					that.registerArray.country_code = response.countryCode
					that.registerArray.timezone 	= response.continent + '/' + response.city
				}).catch((/* data, status */) => {
					// console.log(data, status)
				})
			},
			
			validatorField(element) {
				// console.log(element)
				let that = this
				if( element == 'name' ) {
					that.validatorError.name = that.registerArray.name.length < 3 ? 'The name field must be at least 3 characters.' : ''
				} else if ( element == 'email' ) {
					that.validatorError.email = that.registerArray.email.length < 3 ? 'The email address is not valid.' : ''
				} else if ( element == 'password' ) {
					that.validatorError.password = that.registerArray.password.length < 8 ? 'The password field must be at least 8 characters.' : ''
				}
			},
			
			onInput({ number, isValid, country }) {
				this.validatorError.phone = isValid ? '' : 'This phone number is not valid.'
				this.registerArray.telCode 		= '+' + country.dialCode
				this.registerArray.country_code = country.iso2
				
				const ct = require('countries-and-timezones');
				const countryInfo = ct.getCountry(country.iso2);
				if( countryInfo != null ) {
					this.registerArray.timezone = countryInfo.timezones[0]
				}
				
				console.log(number.input)
			},
			
			CheckoutPlan() {
				let that = this
				window.Paddle.Checkout.open({
					product			: that.registerArray.plan_id,
					passthrough		: that.registerArray.email,
					email			: that.registerArray.email,
					allowQuantity	: false, 
					disableLogout	:true,
					message		    : '100% Money Back Guarantee!',
					customData: {
        tolt_referral: window.tolt_referral,
    },
					successCallback	: function() {
						try {
							window.gtag('event', 'conversion', {'send_to': 'AW-11078688301/uJahCPKm04kYEK283aIp'});
							window.fbq('track', 'Ajout paiement');
							
						} catch (error) {
							console.log(error)
						}
						
						
						that.$store.dispatch('clientSession').then( () => {
							window.location = '/newsource'
							that.processing = false
						})
						
					},
					closeCallback 	: function() {
						try {
							window.gtag('event', 'conversion', {'send_to': 'AW-11078688301/0tgKCPbCqvkYEK283aIp'});
							window.fbq('track', 'Gratuit');
						} catch (error) {
							console.log(error)
						}
						
						
						/*let token = "";
						let password =  that.registerArray.password;
						let email =  that.registerArray.email;
						that.$store.dispatch('login', { email, password, token,lang : navigator.language }).then( /* () => that.$router.push('/')  ({data}) => {
							/* console.log( data ) 
							that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
							if ( typeof( data.user.avatar ) === 'undefined' || data.user.avatar == null || data.user.avatar == '' ) {
								data.user.avatar = ''
							}
							that.$auth.user  = (() => {
								return  data.user
							})
							let metaData = {
									name: data.user.name,
									email : data.user.email,
									inscription : new Date()
								}
								window.tap('customer',data.user.user.email, {meta_data : metaData});

							window.localStorage.setItem('oml-token', data.access_token);
							window.localStorage.setItem('oml-user', JSON.stringify(data.user) );
							window.localStorage.setItem('calendly_url', data.calendly_url)
							
							// window.localStorage.setItem('action', 'bl')
							that.success = true
							that.$store.dispatch('clientSession').then( () => {
								if( data.lead ) {
									// that.$router.push({name: 'myleads'})
									window.location = '/myleads'
								} else {
									// that.$router.push({name: 'newSource'})
									window.location = '/newsource'
								}
								that.processing = false
							})
					
								// const redirectTo = redirect ? redirect.from.name : this.$auth.user().role === 'sysadmin' ? 'admin.dashboard' : 'myleads'
								// this.$router.push({name: redirectTo})
						}).catch( /* err => console.log(err)  ( response  ) => {
							console.log(response)
							this.processing = false
							this.has_error = true
						})*/
					
						
						//window.localStorage.clear()
					},
				})
				
			},
			
			ChoosePlan(plan) {
				let that = this
				that.PlanQuery.plan_type = plan.plan_type
				that.registerArray.plan_id = plan.id
			},
			register() {
				// test commit master
				let that = this
				var checkEmpty = false
				that.feedbackMessage = ''
				Object.values(that.validatorError).forEach( function(item) {
					if( item != '' ) {
						that.feedbackMessage += ' - ' + item + '<br>'
						checkEmpty = true
					}
				} )
				
				if( checkEmpty ) {
					return false
				}
				
				that.processing = true
				that.registerArray.lang = navigator.language
				if( typeof( this.$route.query ) != "undefined" ) {
				that.registerArray.utm_source = this.$route.query.utm_source
				that.registerArray.utm_term = this.$route.query.utm_term
				that.registerArray.utm_campaign = this.$route.query.utm_campaign
				that.registerArray.utm_medium = this.$route.query.utm_medium
				}
				that.$store.dispatch('register', that.registerArray ).then( ({data}) => {
					
					if( data.success ) {
						
						let user = data.user
						
						that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
						if ( typeof( user.avatar ) === 'undefined' || user.avatar == null || user.avatar == '' ) {
							user.avatar = ''
						}
						that.$auth.user = (() => {
							return user
						})
						console.log(that.registerArray)
						window.localStorage.setItem('oml-token', data.access_token);
						window.localStorage.setItem('oml-user', JSON.stringify(user) );
						window.localStorage.setItem('action', 'register')
						window.tolt.signup(that.registerArray.email)

						window.gist.track("Signed Up", {
							userName: user.email,
							type: "With CC",
							companyId: ""
							});


						that.CheckoutPlan(data.checkout)

						
					} else {
						that.feedbackMessage = 'That email address has already been used'
						that.processing = false
					}
					
					
				}).catch( ( {response} ) => {
					that.feedbackMessage = ''
					let error = response.data.errors
					Object.keys(error).forEach( function( item ) {
						if(that.feedbackMessage != '') {
							that.feedbackMessage += '<br>'
						}
						that.feedbackMessage += "- " + error[item][0]
					} )
					that.processing = false
				})
				
				// console.log('qsds')
				/* var app = this
				this.$auth.register({
					data: {
						email	: app.email,
						password: app.password,
						password_confirmation: app.password_confirmation
					},
					success: function () {
						app.success = true
						this.$router.push({name: 'login', params: {successRegistrationRedirect: true}})
					},
					error: function (res) {
						// console.log(res.response.data.errors)
						app.has_error = true
						app.error = res.response.data.error
						app.errors = res.response.data.errors || {}
					}
				}) */
			}
			
		},
		computed: {
			StartedDay() {
				
				var days = 14;
				var date = new Date();
				var res  = date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
				
				// var d=new Date();
				
				var weekday  = ["Sun", "Mon", "Tues", "Wednes", "Thurs", "Fri", "Satur"]
				var monthday = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
				
				
				var today = new Date(res);
				var ds     = weekday[today.getDay()]
				var ms     = monthday[today.getMonth()]
				var dd 	  = String(today.getDate()).padStart(2, '0');
				// var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();
				
				today = ds + ', ' + dd + ' ' + ms + ' ' + yyyy;
				// today = mm + '/' + dd + '/' + yyyy;
				
				return today // 'Thu, 24 Dec 2020'
			}
		},
		components: {
			VueTelInput,
		},
	}
</script>

<style lang="scss">
	
	.alert-oml-info {
		color: #0099ca;
		background-color: #00bbf630;
		/* border-color: #00bbf630; */
	}
	
	div.plan-range {
		border: 1px solid #ccc;
		border-radius: 6px;
		padding: 10px;
		cursor: pointer;
		display: flex;
		&.active {
			border: 1px solid #00bbf6;
			box-shadow: 0px 0px 5px #ddd;
		}
		&:hover {
			box-shadow: 0px 0px 5px #ddd;
		}
	}
	img.img-checkmark {
		background-color: rgba(255,255,255,.1);
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 100%;
		margin-top: -10px;
		margin-left: -10px;
	}
	.register, .login {
		.vti__dropdown-list {
			font-size: 12px;
		}
		input.form-control, input.vti__input {
			height: 45px;
			font-size: 13px;
		}
		
		button[type="submit"] {
			height: 50px;
			font-size: 16px;
		}
		
		.form-group {
			label {
				margin-bottom: 1px;
			}
		}
		
	}
</style>

<template>
	<div class="col-md-12 login">
		
		<meta name="viewport" content="width=device-width,initial-scale=1.0">
		<meta name="viewport" content="width=500, initial-scale=1">
		<meta name="viewport" content="initial-scale=1, maximum-scale=1">
		
		<div class="row">
			<div class="col-12 col-md-4 col-sm-8 mx-auto">
				
				<div class="login_left-side login_bouless">
					<div class="login_left-side-body">
						<div class="logo text-center" style="margin-left: auto; margin-right: auto;">
							<router-link :to="{name: 'home'}"><img :src="$store.state.DefaultLogo" style="width: 230px;" alt="" /></router-link>
						</div>
						
						<div class="text-center">
							<h4>{{ $t('TypeYourNewPassword') }}</h4>
							<!--p>Check your Email to get your Code</p-->
							<p><router-link :to="{name: 'login'}" class="back_login">{{ $t('BackToLogin') }}</router-link></p>
						</div>
						
						<div class="login_form" style="padding: 0 1rem;">
							
							<div class="row">
								<div class="col-md-12">
									<form autocomplete="on" @submit.prevent="reset" method="post">
										<div class="form-group">
											<input class="form-control" type="email" placeholder="Your Email" 
												required autofocus pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" v-model="resetArray.email" />
											<!--span class="help-block" v-if="has_error"><strong>We can't find a user with that e-mail address.</strong></span-->
										</div>
										<div class="form-group">
											<input class="form-control" type="password" :placeholder="$t('Password')" required v-model="resetArray.password" />
											<!--span class="help-block" v-if="has_error"><strong>The password must be at least 6 characters.</strong></span-->
										</div>
										<div class="form-group">
											<input class="form-control" type="password" :placeholder="$t('ConfirmPassword')" required v-model="resetArray.confirmPwd" />
										</div>
										<div class="alert alert-danger" role="alert" v-if="message != ''">
											<!--small class="form-text"> <i class="fa fa-exclamation-triangle"></i> {{ message }}</small-->
											<small class="form-text" v-html="message"></small>
										</div>
										<div class="form-group text-center">
											<button type="submit" class="btn-login">{{ $t('Reset') }} <i class="fa fa-spinner fa-spin" v-show="processing"></i></button>
										</div>
									</form>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
	</div>
</template>
<script>
	export default {
		data() {
			return {
				
				processing  : false,
				message		: '',
				
				resetArray 	: {
					token		: '',
					email		: '',
					password	: '',
					confirmPwd	: '',
				},
				
			}
		},
		mounted() {
			//
			this.resetArray.token = this.$route.params.token
			// console.log( this.$route.params.token )
		},
		methods: {
			reset() {
				// get the redirect object
				let that = this
				that.processing = true
				
				let email 		= that.resetArray.email
				let password 	= that.resetArray.password
				let confirmPwd 	= that.resetArray.confirmPwd
				let token	 	= that.resetArray.token
				
				that.$http({
					url: '/auth/password/reset',
					method: 'POST',
					data: {email:email, password:password, password_confirmation:confirmPwd, token:token,lang : navigator.language}
				})
				.then((res) => {
					if( res.data.success ) {
						that.$router.push({name: 'login'})
					} else {
						that.message	= res.data.message
					}
					that.processing = false
				}, ({response}) => {
					that.processing = false
					let errors 		= response.data.errors
					
					let varKey  = Object.keys( errors ) //, valuesObj = Object.values( response.data.message );
					var message = ''
					let objMsg  = errors
					varKey.forEach( function(item) {
						message += objMsg[item] + "<br>"
					} )
					that.message	= message
					// that.message	= response.data.message
				})
				
			}
		}
	}
</script>
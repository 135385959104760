<template>
	<div class="container-fluid register register-fb">
		
		<meta name="viewport" content="width=device-width,initial-scale=1.0">
		<meta name="viewport" content="width=500, initial-scale=1">
		<meta name="viewport" content="initial-scale=1, maximum-scale=1">
		
		<!-- Form -->
		<div class="row">
			
			
			<div class="col-lg-5 col-xl-4 d-none d-lg-flex gradient-half-primary-v1" style="position: fixed; overflow-y: scroll;height: 100vh;z-index: 9;" v-if="1 == 2">
				<div class="w-100 p-5" style="display:flex; flex-direction: column; justify-content: space-evenly; height: 100%;">
					<div style="margin-bottom: 2rem; margin-top: 0rem;"> <!-- js-slick-carousel u-slick  -->
						<div class="slick-list draggable">
							<!-- Testimonial -->
							<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px;" v-for="(slide, key) in sliderReview" v-show="slide.selected" v-bind:key="`slideR-${key}`" @click="sliderSelect(slide)">
								<div class="text-left mb-0">
									<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;" v-html="slide.description"></blockquote>
								</div>
								<div class="d-flex justify-content-start align-items-center mt-2">
									<div class="d-flex align-items-center justify-content-start">
										<img :src="slide.avatar" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
										<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">{{ slide.name }}<br><span class="text-muted" style="font-size: .7rem;">{{ slide.job }}</span></h4>
									</div>
								</div>
							</div>
							<!-- End Testimonial -->
						</div>
						<ul class="js-pagination text-left u-slick__pagination mt-4 mb-0 justify-content-start" role="tablist">
							<li v-for="(slide, key) in sliderReview" :class="[ slide.selected ? 'slick-active-right slick-active slick-current' : '']" @click="sliderSelect(slide)" role="presentation" v-bind:key="`slide-${key}`">
								<span></span>
							</li>
						</ul>
					</div>
					<div class="py-3 px-2 px-sm-9 mt-3 mb-2">
						<!-- Info -->
						<ul class="list-unstyled">
							<li class="media pb-3 d-none">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark.svg" class="img-checkmark" />
								</div>
								<div class="media-body">
									<h4 class="h6 text-white mb-1">Absolutely FREE to try</h4>
									<p class="text-white-70" style="font-size: .8rem; margin-bottom: 0;">No catch. See it for yourself for 7 days absolutely free.</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3 d-none"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark.svg" class="img-checkmark" />
								</div>
								<div class="media-body">
									<h4 class="h6 text-white mb-1">Cancel or Downgrade anytime</h4>
									<p class="text-white-70" style="font-size: .8rem;">You can upgrade, downgrade or cancel your subscription anytime. No questions asked.</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark.svg" class="img-checkmark" />
								</div>
								<div class="media-body">
									<h4 class="h6 text-white mb-1">Satisfied or Money Back</h4>
									<p class="text-white-70" style="font-size: .8rem; margin-bottom: 0;">Get your money back in less than 24h, after using our software for 30 days.</p>
								</div>
							</li>
						</ul>
						<!-- End Info -->
					</div>
					<!-- End Quote -->
				</div>
			</div>
			
			<div class="col-12">
				<div class="row no-gutters">
					<div class="col-md-5 col-12 m-auto"><!--col-12 col-xl-5 col-lg-6 offset-lg-6 offset-xl-6 space-3 space-lg-0 ohmylead-->
						<form class="js-validate mt-5 pt-2" @submit.prevent="register">
							<div class="mb-4 mt-3">
								<img src="img/logo_pitchground.jpg" class="logo-icon logo" style="width:400px; margin: 0;">
							</div>
							<!-- Title -->
							<div class="mt-3">
								<div v-if="registerArray.token == ''">
									<h3 class="dark-blue-text" style="font-size: 1.2rem; font-weight: 500;">{{PageContent[lang].title}}</h3>
									<h3 style="color: #8c98a4;font-size: 1rem;margin-bottom: 2rem;font-weight: 300;">{{PageContent[lang].other_title}} </h3>
								</div>
								<div v-else style="margin-bottom: 2rem;">
									<h3 class="dark-blue-text" style="font-size: 1.2rem; font-weight: 500;">You've been invited</h3>
									<div v-if="invitation.user != ''">
										<b>{{ invitation.user }}</b> has invited you (<span id="email">{{ invitation.email }}</span>) to join <b id="workspace">{{ invitation.workspace }}</b> 
										<span id="email_of_invitor" class="d-none">{{ invitation.email_of_invitor }}</span> 
										<span id="role_of_invitor" class="d-none">{{ invitation.role_of_invitor }}</span> 
										<span id="role_of_invited" class="d-none">{{ invitation.role_of_invited }}</span> 
									</div>
								</div>
								
							</div>
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label d-none" for="name">{{PageContent[lang].fullname.title}}</label>
								<input type="text" class="form-control" @blur="validatorField('name')" id="name-signup" :placeholder="PageContent[lang].fullname.placeholder" required v-model="registerArray.name" />
								<small class="form-text text-danger" v-if="validatorError.name != ''">{{ validatorError.name }}</small>
								<!-- autofocus -->
							</div>
							<!-- End Form Group -->
							<!-- Form Group -->
							<!--div class="form-group has-error">
								<label class="form-label" for="signinSrPhone">Phone</label>
								<vue-tel-input style="border: 1px solid #d5dae2" type="text" name="phone" @onInput="onInput" v-model="phone" :validate="'min:3'" class="form-control" placeholder="Phone" aria-label="Phone"></vue-tel-input>
								<span class="help-block hidden" style="display:none;">phone</span>
							</div-->
							<!-- End Form Group -->
							
							<!-- Form Group -->
							<!--
							<div class="js-form-message form-group">
								<label class="form-label d-none" for="phone">{{PageContent[lang].phone.title}}</label>
								<vue-tel-input v-model="registerArray.phone" name="phone" id="phone-signup" 
								:placeholder="PageContent[lang].phone.placeholder"
								@onInput="onInput" :validate="'min:3'" required></vue-tel-input>
								<small class="form-text text-danger" v-if="validatorError.phone != ''">{{ validatorError.phone }}</small>
							</div>
							-->
							<!-- End Form Group -->
							
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label d-none" for="email-signup">{{PageContent[lang].email.title}}</label>
								<input type="email" class="form-control" id="email-signup" @blur="validatorField('email')" 
									:placeholder="PageContent[lang].email.placeholder" v-model="registerArray.email" 
									required /><!-- pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" -->
								<small class="form-text text-danger" v-if="validatorError.email != ''">{{ validatorError.email }}</small>
							</div>
							<!-- End Form Group -->
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label d-none" for="password">{{PageContent[lang].password.title}}</label>
								<div class="input-group" :title="PageContent[lang].password.placeholder">
									<input :type="[showPwd ? 'text' : 'password']" class="form-control" id="password-signup" :placeholder="PageContent[lang].password.placeholder" @blur="validatorField('password')" required v-model="registerArray.password" />
									<div class="input-group-append">
										<a href="javascript:void(0)" class="input-group-text" id="basic-addon2" style="background-color: #fff;" @click="showPwd = !showPwd">
											<span v-if="showPwd">{{PageContent[lang].view.hide}}</span>
											<span v-else>{{PageContent[lang].view.show}}</span>
											<!--i class="fa fa-eye-slash"></i-->
										</a>
									</div>
								</div>
								<small class="form-text text-danger" v-if="validatorError.password != ''">{{ validatorError.password }}</small>
							</div>
							<!-- End Form Group -->
																		
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label d-none" for="coupon">{{PageContent[lang].coupon}}</label>
								<input type="text" class="form-control" @blur="validatorField('coupon')" id="coupon-signup" :placeholder="PageContent[lang].coupon" required v-model="registerArray.coupon" />
								<small class="form-text text-danger" v-if="validatorError.coupon != ''">{{ validatorError.coupon }}</small>
								<!-- autofocus -->
							</div>
							<!-- End Form Group -->

							<div class="alert alert-danger" role="alert" v-if="feedbackMessage != ''">
								<small class="form-text"> <!--i class="fa fa-exclamation-triangle"></i--> <span v-html="feedbackMessage"></span></small>
							</div>
							
							<div class="row align-items-center mb-1" v-if="success">
								<div class="col-12">
									<div class="alert alert-success" id="signup-success-alert" style="font-size: 12px;">Welcome on board !</div>
								</div>
							</div>
							<!-- Button -->
							<div class="row align-items-center mb-2">
								<div class="col-12 col-lg-12 text-right">
									<button type="submit" class="btn btn-primary btn-block btn-getstarted">{{ PageContent[lang].signup }} <i class="fa fa-spinner fa-spin" v-show="processing"></i></button><!-- transition-3d-hover -->
								</div>
							</div>
							<!-- End Button -->
							
							<!--div class="row text-center">
								<div class="col-5"><hr style="border-color: #b7b7b7;"></div>
								<div class="col-2 my-auto">OR</div>
								<div class="col-5"><hr style="border-color: #b7b7b7;"></div>
							</div>
							<div class="mt-2 mb-4">
								<v-facebook-login 
									app-id="155127881962747" 
									:loginOptions="{ scope: 'ads_management,manage_pages,public_profile,leads_retrieval', return_scopes : true }" 
									@sdk-init="sdkLoaded" 
									@login="onLogin" 
									@logout="onLogout">
								</v-facebook-login>
							</div-->
							
							<div class="col-12 col-lg-12 mb-5 text-center">
								<span class="small text-muted">{{ PageContent[lang].small_text }}</span>
								<router-link :to="{name: 'login'}" class="small">{{ PageContent[lang].login }}</router-link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- End Form -->
	</div>
</template>
<script>
	// import { VueTelInput } from 'vue-tel-input'
	// import { VFBLogin as VFacebookLogin } from 'vue-facebook-login-component'
	export default {
		data() {
			return {
				showPwd		   : false,
				registerArray  : {
					name   	   : '',
					email	   : '',
					password   : '',
					token 	   : '',
					phone	   : '',
					telCode    : '',
					country_code: '',
					timezone   : '',
					coupon	   : '', 
					interface  : 'trial'
				},
				
				// password_confirmation: '',
				validatorError	: { name:'', phone:'', email:'', password:'', coupon:'' },
				feedbackMessage : '',
				processing		: false,
				
				sliderReview: [
					{
						id	:1,
						name:'David Somerfleck',
						selected: true,
						avatar: 'https://old.ohmylead.com/assets/v2/img/david-somerfleck.png',
						job	: 'Digital Expert at DMS - Florida',
						description:'Handy for scaling lead gen processes. At the moment we do these things fairly manually however when I scale up this will help keep data in the right place'
					},
					{
						id	:2,
						name:'Martin Belhoste',
						selected: false,
						avatar: 'https://old.ohmylead.com/assets/v2/img/martin-belhoste.png',
						job	: 'Sales manager at Moët Hennessy - France',
						description:'In short, this is like air traffic control for your leads - it makes sure that no leads fall through the cracks and they get the most appropriate immediate response'
					},
					{
						id	:3,
						name:'Haim pekel',
						selected: false,
						avatar: 'https://old.ohmylead.com/assets/v2/img/haim-pekel.png',
						job	: 'CEO at Press On it - London',
						description:'My sales reps can now dive into a lead without bouncing around, everything is synced inside the app, all incoming leads are qualified, all the details and lead nurturing process is updated in the CRM, dreamy.'
					},
				],
				PageContent : {
					'en' : { 
						titletop: 'Start your FREE 7 day trial',
						title: 'Hey !',
						other_title	: 'Please share your name, email and Pitch Ground code to get started. Thank you so much and happy to have you !',
						fullname: { title: 'First & last name', placeholder: 'Your full name' },
						phone: { title: 'Phone', placeholder: 'Enter a phone number' },
						email: { title: 'Email address', placeholder: 'Your work email' },
						password: { title: 'Password', placeholder: 'Type Your Password' },
						view: { show: 'Show', hide: 'Hide' },
						small_text: 'Already have an account ?',
						login: 'Login',
						signup: 'YES! LET ME IN!',
						coupon: 'Pitch Ground Code'
					},
					'fr' : { 
						titletop: 'Démarrez votre essai GRATUIT de 7 jours',
						title: 'Hey !',
						other_title	: 'Veuillez partager votre nom, votre adresse e-mail et votre code Pitch Ground pour commencer. Merci beaucoup et heureux de vous accueillir !',
						fullname: { title: 'Nom et prénom', placeholder: 'Comment vous appelez-vous ?' },
						phone: { title: 'Téléphone', placeholder: 'Votre numéro de téléphone' },
						email: { title: 'Adresse e-mail', placeholder: 'Votre adresse email professionnel' },
						password: { title: 'Mot de passe', placeholder: 'Votre mot de passe (Afin que vous puissiez accéder à votre compte facilement et en toute sécurité)' },
						view: { show: 'Show', hide: 'Hide' },
						small_text: 'Vous avez déjà un compte ?',
						login: "S'identifier",
						signup: 'YES! LET ME IN!',
						coupon: 'Code Pitch Ground'
					},
				},
				lang : 'en',
				
				invitation: { user: '', team: '', email: '', role_of_invitor: '', email_of_invitor: '', role_of_invited: '' },
				success	  : false
			}
		},
		mounted() {
			//
			let that = this
			
			// console.log( window.location )
			if( typeof( that.$route.params.token ) != "undefined" ) {
				that.registerArray.token = that.$route.params.token
				that.CheckToken()
			}
			that.getCountry()
			
			let lang = that.$route.query.lang
			if( typeof( lang ) != "undefined" && lang != null ) {
				lang = lang.toLowerCase() == 'fr' ? lang.toLowerCase() : 'en'
				that.lang = lang
			}
				
			that.getCoupon(this.$route.params.coupon)
		},
		methods: {
			getCoupon(coupon) {
				if (coupon) {
					this.registerArray.coupon = coupon;
					this.validatorField('coupon');
				}
			},
			sdkLoaded() {},
			
			onLogin(AuthData) {
				// console.log(AuthData)
				let that = this
				if (AuthData.status == "connected") {
					
					let RequestData = AuthData.authResponse
					
					let params = { limit: 200, access_token: AuthData.authResponse.accessToken };
					window.FB.api('/me', 'GET', params, { fields: 'id,name,email' },
					(response) => {
						RequestData.name 	= response.name
						RequestData.email 	= response.email
						// RequestData.client_id = ''
						RequestData.grantedScopes = 'pages_show_list,ads_management,business_management,leads_retrieval,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,public_profile,email'
						that.handleSessionResponse(RequestData)
					})
					
				}
			},
			handleSessionResponse(response) {
				
				let that = this
				let DataFb = response
				
				that.has_error = false
				that.processing = true
				
				DataFb.country_code 	= that.country_code
				DataFb.timezone 		= that.timezone
				
				that.$store.dispatch('LoginFbBtn', DataFb).then( ({data}) => {
					/* console.log( data ) */
					that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
					if ( typeof( data.user.avatar ) === 'undefined' || data.user.avatar == null || data.user.avatar == '' ) {
						data.user.avatar = ''
					}
					that.$auth.user  = (() => {
						return  data.user
					})
					
					window.localStorage.setItem('oml-token', data.access_token);
					window.localStorage.setItem('oml-user', JSON.stringify(data.user) );
					
					// window.localStorage.setItem('action', 'bl')
					that.success = true
					that.$store.dispatch('clientSession').then( () => {
						if( data.lead ) {
							// that.$router.push({name: 'myleads'})
							window.location = '/myleads'
						} else {
							// that.$router.push({name: 'newSource'})
							window.location = '/newsource'
						}
						that.processing = false
					})
					
					// const redirectTo = redirect ? redirect.from.name : this.$auth.user().role === 'sysadmin' ? 'admin.dashboard' : 'myleads'
					// this.$router.push({name: redirectTo})
				}).catch( () => {
					that.processing = false
					that.has_error = true
				})
			},
			
			onLogout() {},
			
			
			
			
			CheckToken() {
                
				let that  = this
				let url   = '/invitation/' + that.registerArray.token
				let method= 'GET'
				let data  = {}
				
				that.$http({ url: url, method: method, data: data }).then((response) => {
					let invitation 		= response.data
					that.invitation.user  		= invitation.user
					that.invitation.workspace  	= invitation.workspace
					that.invitation.email 		= invitation.email
					that.invitation.email_of_invitor = invitation.email_of_invitor
					that.invitation.role_of_invitor = invitation.role_of_invitor
					that.invitation.role_of_invited = invitation.role_of_invited
					that.registerArray.email = invitation.email
				}).catch(( response ) => {
					console.log(response)
				})
				
            },
			
			getCountry() {
				// http://ip-api.com/json
				// https://extreme-ip-lookup.com/json/
				let that = this
				fetch('https://extreme-ip-lookup.com/json/').then( res => res.json()).then(response => {
					that.registerArray.country_code = response.countryCode
					that.registerArray.timezone 	= response.continent + '/' + response.city
				}).catch((/* data, status */) => {
					// console.log(data, status)
				})
			},
			validatorField(element) {
				let that = this
				if( element == 'name' ) {
					that.validatorError.name = that.registerArray.name.length < 3 ? 'The name field must be at least 3 characters.' : ''
				} else if ( element == 'email' ) {
					that.validatorError.email = that.registerArray.email.length < 3 ? 'The email address is not valid.' : ''
				} else if ( element == 'password' ) {
					that.validatorError.password = that.registerArray.password.length < 8 ? 'The password field must be at least 8 characters.' : ''
				} 
			},
			sliderSelect(slide) {
				
				this.sliderReview.forEach( function(item) {
					item.selected = false
				} );
				slide.selected = !slide.selected
				
			},
			
			onInput({ number, isValid, country }) {
				this.validatorError.phone = isValid ? '' : 'This phone number is not valid.'
				this.registerArray.telCode 		= '+' + country.dialCode
				this.registerArray.country_code = country.iso2
				
				
				const ct = require('countries-and-timezones');
				const countryInfo = ct.getCountry(country.iso2);
				if( countryInfo != null ) {
					this.registerArray.timezone = countryInfo.timezones[0]
					// console.log( countryInfo.timezones[0] )
					// console.log( countryInfo.timezones, country.iso2 )
				}
				
				
				console.log(number.input)
				// console.log(number, isValid, country)
			},
			
			register() {
				console.log(this.registerArray.coupon)
				let that = this
				var checkEmpty = false
				that.feedbackMessage = ''
				Object.values(that.validatorError).forEach( function(item) {
					if( item != '' ) {
						that.feedbackMessage += ' - ' + item + '<br>'
						checkEmpty = true
					}
				} )
				if( checkEmpty ) {
					return false
				}
				that.processing = true
				that.$store.dispatch('pitchground_register', that.registerArray )
				.then( ({data}) => {
					if( data.success ) {
						let user = data.user
						that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
						if ( typeof( user.avatar ) === 'undefined' || user.avatar == null || user.avatar == '' ) {
							user.avatar = ''
						}
						that.$auth.user = (() => {
							return user
						})
						window.localStorage.setItem('oml-token', data.access_token);
						window.localStorage.setItem('oml-user', JSON.stringify(user) );
						window.localStorage.setItem('action', 'register')
						that.success = true
						that.$store.dispatch('clientSession').then( () => {
							window.location = '/newsource'
							that.processing = false
						})
					} else {
						that.feedbackMessage = 'That email address has already been used'
						that.processing = false
					}
				})
				.catch( ( {response} ) => {
					that.feedbackMessage = ''
                  //if( typeof(response) != 'undefined' && typeof(response.data) != 'undefined' && typeof(response.data.errors) != 'undefined' ) {
                    if(!response.success) {
                        let error = response.data.errors
                        if(error){
							Object.keys(error).forEach( function( item ) {
								if(that.feedbackMessage != '') {
									that.feedbackMessage += '<br>'
								}
								that.feedbackMessage += "- " + error[item][0]
							} )
						}
					}
					that.processing = false
				})				
			}
		},
		components: {
			// VueTelInput,
			// VFacebookLogin
		},
	}
</script>

<style lang="scss">
	img.img-checkmark {
		background-color: rgba(255,255,255,.1);
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 100%;
		margin-top: -10px;
		margin-left: -10px;
	}
	.register, .login {
		.vti__dropdown-list {
			font-size: 12px;
		}
		input.form-control, input.vti__input {
			height: 45px;
			font-size: 13px;
		}
		
		button[type="submit"] {
			height: 50px;
			font-size: 16px;
		}
		
		.form-group {
			label {
				margin-bottom: 1px;
			}
		}
		
	}
</style>

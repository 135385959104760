<template>
	<div class="col-md-12 login">
		<section class="hbox stretch">
			<div class="logins_pages">
				<div class="row">
					
					<div class="col-md-3"></div>
					<div class="col-md-6"> <!--  col-md-offset-3 -->
						<div class="login_left-side login_bouless">
							<div class="login_left-side-body">
								<div class="logo text-center" style="margin-left: auto; margin-right: auto;">
									<router-link :to="{name: 'home'}"><img :src="$store.state.DefaultLogo" style="width: 230px;" alt="" /></router-link>
								</div>
								<div class="login_text-heading text-center" style="color: #2b334a; margin-bottom: 30px;">
									<h4>{{ $t('login_title') }}</h4>
								</div>
								
								<div class="login_form" style="padding: 0 1rem;">
									<div class="row">
										<div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
											<div class="row">
												<div class="col-md-12">
													<form autocomplete="on" @submit.prevent="LoginToken" method="post">
														<div class="form-group"><!-- has-error -->
															<label class="text-left" for="token">{{ $t('Token') }}</label>
															<input type="text" name="token" id="token" class="form-control" placeholder="Token" v-model="token" required autofocus />
														</div>
														<!--input type="submit" class="btn-login" value="Login to your account"-->
														<button type="submit" class="btn-login">{{ $t('Login') }} <i class="fa fa-spinner fa-spin" v-show="processing"></i></button>
														<div :class="['alert', is_success ? 'alert-success' : 'alert-danger']" v-if="message != ''">{{ message }}</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3"></div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				token		: '',
				is_success	: false,
				processing	: false,
				message		: ''
			}
		},
		mounted() {
			
		},
		methods: {
			LoginToken () {
				
				let that = this
				that.is_success = false
				that.processing = true
				let token 	 	= that.token 
				
				that.$store.dispatch('LoginToken', { token: token, provider : "system user" ,lang:navigator.language}).then( ({data}) => {
					
					that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
					if ( typeof( data.user.avatar ) === 'undefined' || data.user.avatar == null || data.user.avatar == '' ) {
						data.user.avatar = ''
					}
					that.$auth.user  = (() => {
						return  data.user
					})
					
					window.localStorage.setItem('oml-token', data.access_token);
					window.localStorage.setItem('oml-user', JSON.stringify(data.user) );
					
					that.$store.dispatch('clientSession').then( () => {
						if( data.lead ) {
							// that.$router.push({name: 'myLeads'})
							window.location = '/myLeads'
						} else {
							// that.$router.push({name: 'newSource'})
							window.location = '/newSource'
						}
						that.processing = false
					})
					
					// const redirectTo = redirect ? redirect.from.name : this.$auth.user().role === 'sysadmin' ? 'admin.dashboard' : 'myLeads'
					// this.$router.push({name: redirectTo})
				}).catch( ( {response} ) => {
					that.processing = false
					
					that.is_success = response.data.success
					that.message 	= response.data.message
					
				})
				
			},
		}
	}
</script>
<style>
	
</style>